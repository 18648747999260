@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

/* footer.footer {    bottom: 0px;  position: absolute;  display: flex;  width: 77%; } */
.btn-play-on {padding: 0.3rem 0.1rem 0.4rem 0.7rem;}
.chat-bg {background-color: #fff;}
.avatar-icons {    text-align: right; margin-top: 8px; position: absolute; right: 0px;}
.dropdown-menu.dropdown-menu-lg.show {right: 0px;}
.website-list-group .list-group-item {border: none; border-bottom: 2px solid rgba(0, 0, 0, .125);}
.MuiBox-root.css-1n3h4wo {display: block; height: auto; background-color: unset;}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {text-transform: capitalize !important;}
.css-19kzrtu {padding: 0px !important;}
.btn-microsoft {background: #2f2f2f; color: #fff; }
.btn-microsoft:hover {color: #fff;}
.form-control.form-control-xsm {padding: .01rem .85rem;}
main.content {min-height: 100vh;}
.form-btn-space a {margin-right: 8px;}
/* .avatar-icons.hidden {display: none;}
.avatar-icons.visible {display: block;} */
/* .avatar_selection.selected .hidden {display: block;} */
.avatar_selection.hidden .avatar-icons {display: none;}
.avatar_selection.selected.hidden .avatar-icons {display: block;}
.deleteIcon, .EditIcon, .RefreshIcon, .viewIcon {border: none; background: none;}
.sidebar-item .sidebar-link.active:hover, .sidebar-item>.sidebar-link.active {
    background: linear-gradient(90deg, rgba(59, 125, 221, .1), rgba(59, 125, 221, .0875) 50%, transparent);
    border-left-color: #3b7ddd;
    color: #e9ecef;
}
.pronoun-icons button {display: block; margin-bottom: 10px; width: 65px;}
.pronoun-icons .btn .feather {height: 20px; width: 20px;}
.multiselectSize {min-height: 250px;}
.google_login_button iframe {width: 100% !important;}
.green-gradient-bg {
    background-color: #19c37d;
    background-size: cover;
}
.purple-gradient-bg {
    background-color: #5436da;
    background-size: cover;
}
.pink-gradient-bg {
    background-color: #dd5ce5;
    background-size: cover;
}
.red-gradient-bg {
    background-color: #ef4146;
    background-size: cover;
}
.orange-gradient-bg {
    background-color: #f4ac36;
    background-size: cover;
}

.icon-item-icon {
    border-radius: 8px;
    color: #fff;
    flex: 0 0 auto;
    font-size: 24px;
    line-height: 0px;
    padding: 15px;
}
main.content .PricingTable.has-fourItems .PricingTable-grid {width: 100% !important;}
main.content main.content {padding: 0px;}
/* Styles for react-modal */
.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: '-50%';
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    outline: none;
    height: 250px;
    width: 500px;
  }
  
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
  }
  

/* .dVFlIL {width: 20% !important;} */
.badge-success-light, a.badge-success-light {
    background-color: #1cbb8c26;
    color: color-yiq(rgba(28,187,140,.15));
    color: #1cbb8c;
  }
  .badge-danger-light, a.badge-danger-light {
    background-color: #dc354526;
    color: color-yiq(rgba(220,53,69,.15));
    color: #dc3545;
  }
  .chat-message-left, .chat-message-right {
    display: flex;
    flex-shrink: 0;
  }  
  .chat-message-right {
    flex-direction: row-reverse;
    margin-left: auto;
  }
  .fa-2x {
    font-size: 1.5em;
  }


/* Modern Css Style */
body {
  font-family: "Roboto", sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
  color: #333;
}

h1, h5 {
  color: #444;
}

/* Card styles */
.card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.card-header {
  padding: 1rem;
  border-bottom: 1px solid #eaeaea;
  background: #f5f5f5;
  border-radius: 10px 10px 0 0;
}

.card-title {
  font-weight: bold;
  color: #222;
}

/* Input styles */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="file"],
.select__control {
  display: block;
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus,
.select__control--is-focused {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

/* Button styles */
.btn {
  display: inline-block;
  padding: 10px 20px;
  /* font-size: 1rem; */
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.btn:hover {
  background: #0056b3;
  transform: translateY(-2px);
}

.btn:active {
  background: #003f7f;
}

/* Tab styles */
.MuiTab-root {
  color: #555 !important;
  text-transform: capitalize !important;
  font-weight: bold !important;
}

.Mui-selected {
  color: #007bff !important;
}

/* Avatar styles */
img {
  border-radius: 50%;
  margin-bottom: 10px;
}

small {
  font-size: 0.85rem;
  color: #666;
}

/* Alert styles */
.alert {
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #e7f3fe;
  color: #3178c6;
  font-weight: bold;
}

.alert-info {
  background-color: #e9f7e9;
  color: #2c662d;
}

/* Custom file input */
input[type="file"] {
  border: 1px solid #ccc;
  background: #f5f5f5;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

/* TabPanel content spacing */
.tab-content {
  margin-top: 20px;
}
.file-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 20px;
  border: 2px dashed #007bff;
  border-radius: 10px;
  background-color: #f9f9f9;
  max-width: 400px;
  margin: 20px auto;
}

.file-loading input[type="file"] {
  display: none; /* Hide the default file input */
}

.file-loading label {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.file-loading label:hover {
  background-color: #0056b3;
}

.file-loading .btn {
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.file-loading .btn:hover {
  background-color: #1e7e34;
}

.file-loading .btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.file-loading p {
  font-size: 14px;
  color: #6c757d;
  margin-top: 10px;
  text-align: center;
}
.card-2 {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-2:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* .avatar-icons.hidden {display: none;}
.avatar_selection.visible .avatar-icons.hidden {display: block;} */
.avatar_selection .visible {display: block;}
.avatar_selection .hidden {display: none;}
.fa-spinner {
  font-size: 16px;
  margin-bottom: 0px;
}
.sidebar-header-name {font-size: 15px;padding: 16px 0px;display: inline;}
.form-control {min-height: 45px;}
.text-decoration-none {text-decoration: none;}
.pagination-display-ruby {display: ruby;}
.RefreshIcon i {
  transition: all 0.3s ease; /* Add transition for a smooth effect */
}

.RefreshIcon:hover {
  cursor: pointer;
  transform: scale(1.1); /* Slightly enlarge on hover */
}
.btn-outline-secondary, .btn-outline-secondary:focus, .btn-outline-secondary:hover {background-color: transparent; background: none; color: #007bff; border: none; box-shadow: 0px 0px #fff;}
